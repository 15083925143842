import { useEffect, useRef, useState } from 'react';

import { Review } from '../../../../reviews';
import MaxWidth from '../../../../shared/MaxWidth';
import * as S from './Testimonials.styles';

export const TestimonialSection = () => {
  const headerRef = useRef(null);
  const [headerMarginLeft, setHeaderMarginLeft] = useState('');

  const updateHeaderMargin = () => {
    if (headerRef.current) {
      // Get the computed marginLeft of the header element
      const marginLeft = window.getComputedStyle(headerRef.current).marginLeft;
      setHeaderMarginLeft(marginLeft);
    }
  };

  useEffect(() => {
    // Update header margin on window resize
    if (typeof window !== 'undefined') {
      updateHeaderMargin();
      window.addEventListener('resize', updateHeaderMargin);
    }

    return () => {
      window.removeEventListener('resize', updateHeaderMargin);
    };
  }, []);

  return (
    <S.Container>
      <MaxWidth ref={headerRef}>
        <S.Heading>Join millions of others...</S.Heading>
        <S.Subheader>Brain.fm users win the day, everyday — see what they have to say!</S.Subheader>
      </MaxWidth>
      <S.TestimonialWrapper>
        <Review headerMarginLeft={headerMarginLeft} />
      </S.TestimonialWrapper>
    </S.Container>
  );
};

export default TestimonialSection;
