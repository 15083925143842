import React, { useContext } from 'react';

import { ModalDispatchContext } from '../../../context/modalContext';
import { Assets } from '../../../utils/assets';
import { SecondaryButtonLink } from '../../button';
import MaxWidth from '../../shared/MaxWidth';
import { H2 } from '../Typography';
import * as S from './CTAbox.styles';

const CTAbox: React.FC = () => {
  const setModalState = useContext(ModalDispatchContext);

  return (
    <S.Wrapper>
      <MaxWidth>
        <S.Container>
          <S.Left>
            <H2>
              Get started with <br />
              Brain.fm today.
            </H2>
            <S.Text>
              Our science-first approach creates music that sounds different and affects your brain
              differently than any other music.
            </S.Text>
            <S.ButtonWrapper>
              <SecondaryButtonLink
                analyticsEvent={{ category: 'CTA', action: 'Signup Button Click' }}
                isAnchortag={false}
                onClick={() => setModalState({ signupVisible: true })}
              >
                START FREE TRIAL
              </SecondaryButtonLink>
            </S.ButtonWrapper>
          </S.Left>
          <S.Right />
          <S.DesktopImage
            alt={Assets.images.ctaLandingPreview.alt}
            src={Assets.images.ctaLandingPreview.url}
          />
          <S.MobileImage
            alt={Assets.images.ctaLandingMobilePreview.alt}
            src={Assets.images.ctaLandingMobilePreview.url}
          />
        </S.Container>
      </MaxWidth>
      <S.GradientLeft
        alt={Assets.images.ctaLandingGradientLeft.alt}
        src={Assets.images.ctaLandingGradientLeft.url}
      />
      <S.GradientRight
        alt={Assets.images.ctaLandingGradientRight.alt}
        src={Assets.images.ctaLandingGradientRight.url}
      />
    </S.Wrapper>
  );
};

export default CTAbox;
