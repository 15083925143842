import { CredentialResponse } from 'google-one-tap';
import React, { useCallback } from 'react';

import { API_BASE_URL, WEB_APP_SIGNIN_URL } from '../../../../../../../globals';
import { useInitializeGoogleAuth } from '../../../../../../../hooks/useInitializeGoogleAuth';
import { useSignUpHandler } from '../../../../../../../hooks/useSignUpHandler';
import { fetcher, setToken } from '../../../../../../../utils';
import {
  trackSignUpAttempt,
  trackSignUpError,
  trackSignUpSuccess,
} from '../../../../../../../utils/analytics';
import { showToast } from '../../../../../../../utils/showToast';
import { validateAndTrackSignUp } from '../../../../../../../utils/validateAndTrackSignUp';
import { GhostButtonContainer } from '../../../../../../button';
import * as S from '../../Hero.styles';

export const ButtonsBlock = () => {
  const trackGoogleSignUp = useCallback(() => {
    trackSignUpAttempt('google');
  }, []);
  const handleGoogleSignUp = useCallback(async (data: CredentialResponse) => {
    if (data.credential) {
      const response = await fetcher(`${API_BASE_URL}/auth/google-signup`, {
        method: 'POST',
        body: JSON.stringify({
          accessToken: data.credential,
        }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        await validateAndTrackSignUp({ token: response.result, event: 'sign_up_google' });
        await trackSignUpSuccess('google');
        setToken(response.result, { isOnboarding: true });
      } else {
        if (response?.messages?.[0].includes('Account already exists')) {
          showToast('Account already exists, you will be redirected to the sign in page');
          setTimeout(() => {
            window.location.href = WEB_APP_SIGNIN_URL;
          }, 2500);
        }
        await trackSignUpError('google', response?.messages?.[0]);
      }
    } else {
      trackSignUpError('google', 'An error occurred while authorizing with Google');
    }
  }, []);

  useInitializeGoogleAuth({
    buttonStyles: {
      theme: 'outline',
    },
    elementId: 'google-button-hero',
    callback: handleGoogleSignUp,
    shouldPrompt: false,
    clickListener: trackGoogleSignUp,
  });

  const onSignUp = useSignUpHandler();

  return (
    <S.ButtonsWrapper>
      <div id="google-button-hero" />
      <S.Button>
        <GhostButtonContainer
          style={{
            padding: '0.6rem 1.7rem',
          }}
          onClick={onSignUp}
        >
          <S.PlayButtonText>Sign up with email</S.PlayButtonText>
        </GhostButtonContainer>
      </S.Button>
    </S.ButtonsWrapper>
  );
};
