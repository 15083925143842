import styled from 'styled-components';

import { mediaQuery } from '../../styles/global';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ScrollContainer = styled.div<{scrollPaddingLeft: string}>`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px 0;
  scroll-snap-type: x mandatory;
  // Ensure scroll starts at header left
  scroll-padding-left: ${({scrollPaddingLeft}) => scrollPaddingLeft};
  cursor: grab;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }

  // Scroll padding not working without this
  &::before {
    content: '';
    display: block;
    min-width: ${({scrollPaddingLeft}) => scrollPaddingLeft};
  }

  user-select: none;
`;

export const CardBottomData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardMainContent = styled.div`
  flex-grow: 1;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.1rem;
`;

export const Author = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
`;

export const Stars = styled.img`
  height: auto;
  max-width: 100px;
`;

export const Hr = styled.hr`
  border-color: #ffffff33;
  width: 100%;
`;

export const ReviewBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
  min-width: 300px;
  max-width: 350px;
  min-height: 240px;
  margin-right: 15px;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px 20px 10px;
  scroll-snap-align: start;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);

  ${mediaQuery.maxWidth.tablet} {
    flex: 0 0 90%;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const CirclesImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
  width: 60%;
`;

export const BgImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
  width: 100%;
  z-index: -1;
`;

export const ButtonContainer = styled.div<{ marginleft: string }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  pointer-events: none;
  margin-top: 10px;
  margin-left: ${({marginleft}) => marginleft};
  ${mediaQuery.maxWidth.tablet} {
    display: none;
  }
`;

export const ScrollButton = styled.button<{ disabled: boolean }>`
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
  pointer-events: all;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
`;
