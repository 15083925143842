import styled from 'styled-components';

import { mediaQuery } from '../../../styles/global';

export const Wrapper = styled.div`
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  min-height: 650px;
  position: relative;
  ${mediaQuery.maxWidth.tablet} {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 38%;
  padding-left: 2rem;
  z-index: 2;
  h2 {
    font-size: 3rem;
  }
  ${mediaQuery.maxWidth.desktop} {
    width: 100%;
    padding: 0 2rem;
  }
  ${mediaQuery.maxWidth.tablet} {
    h2 {
      font-size: 2rem;
    }
  }
`;

export const Right = styled.div`
  width: 62%;
`;

export const ButtonWrapper = styled.div`
  > * {
    transition: 0.3s ease all;
    &:hover {
      scale: 1.1;
    }
  }
  ${mediaQuery.maxWidth.tablet} {
    > * {
      width: 100%;
    }
  }
`;

export const DesktopImage = styled.img`
  width: 100%;
  right: -300px;
  height: auto;
  position: absolute;
  z-index: -1;
  ${mediaQuery.maxWidth.custom(1000)} {
    top: 10%;
  }
  ${mediaQuery.maxWidth.custom(850)} {
    top: 20%;
  }

  ${mediaQuery.maxWidth.tablet} {
    display: none;
  }
`;
export const MobileImage = styled.img`
  margin-top: 25px;
  width: 115%;
  height: auto;
  display: none;

  ${mediaQuery.maxWidth.tablet} {
    display: block;
  }
`;

export const GradientLeft = styled.img`
  pointer-events: none;
  position: absolute;
  left: 0;
  top: -60%;
  max-height: 1200px;
`;
export const GradientRight = styled.img`
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -20%;
  max-height: 1100px;
  ${mediaQuery.maxWidth.tablet} {
    display: none;
  }
`;

export const Text = styled.p`
  margin-bottom: 3rem;
  color: #ffffffbf;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;
